<template>
  <div class="px-4">
    <div class="text-center my-3">
      <BaseButton
        id="download-button"
        class="mb-4"
        variant="secondary-outline"
        :loading="buttonLoading"
        icon="arrow-down-to-line"
        @click.native="downloadReport"
      ></BaseButton>
      <b-tooltip target="download-button" triggers="hover focus" placement="right"> Download CSV </b-tooltip>
    </div>
    <div v-if="loading" class="flex justify-center items-center h-64">
      <LoadingSpinner size="lg" variant="default" />
    </div>
    <div v-else-if="salesReports" class="grid lgrid-cols-1 gap-4 xl:grid-cols-2">
      <div
        v-for="(key, label) in salesReportItems"
        :key="key"
        class="flex flex-col border-slate-300 border rounded-md shadow-md p-4 items-center text-center"
      >
        <h1 class="font-bold">{{ formatLargeCurrency(salesReports[key] / 100) }}</h1>
        <p>{{ label }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ReportService from '@/lib/report-service-v2';
import { downloadFormattedCSV } from '@/lib/download-file';
import BaseButton from '@/components/rbComponents/BaseButton.vue';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
import { BTooltip } from 'bootstrap-vue';
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

export default {
  components: { BaseButton, LoadingSpinner, BTooltip },
  data() {
    return {
      salesReports: null,
      organizationId: null,
      salesReportItems: {
        'Sales All Time': 'salesAllTimeCents',
        'Sales This Year': 'salesThisYearCents',
        'Sales Last Month': 'salesLastMonthCents',
        'Sales Last Week': 'salesLastWeekCents',
        'Sales This Month': 'salesThisMonthCents',
        'Sales This Week': 'salesThisWeekCents',
        'Sales Today': 'salesTodayCents',
        'Sales Yesterday': 'salesYesterdayCents'
      },
      csvData: null,
      buttonLoading: false,
      loading: true
    };
  },
  async mounted() {
    this.getSalesReports();
  },
  methods: {
    async getSalesReports() {
      try {
        this.loading = true;
        this.organizationId = (await getAuth().sessionUser()).organizationUuid;
        this.salesReports = await ReportService.retrieveDashboardSalesReports({ orgId: this.organizationId });
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      } finally {
        this.loading = false;
      }
    },
    formatLargeCurrency(value) {
      if (value >= 10000) {
        return `$${(value / 1000).toFixed(1)}K`;
      }
      return this.formatCurrency(value, { cents: false });
    },
    async downloadReport() {
      this.buttonLoading = true;
      try {
        this.csvData = await ReportService.retrieveDashboardSalesReports({
          orgId: this.organizationId,
          contentType: 'text/csv'
        });

        downloadFormattedCSV('sales-report.csv', this.csvData);
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      } finally {
        this.buttonLoading = false;
      }
    }
  }
};
</script>
