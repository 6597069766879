<template>
  <div class="max-h-1/2">
    <div v-if="loading" class="flex justify-center items-center h-64">
      <LoadingSpinner size="lg" variant="default" />
    </div>
    <div v-else>
      <h4 class="my-3 ml-3">Total Sales - Last 30 Days</h4>
      <ApexChart width="100%" height="550px" type="bar" :options="options" :series="series"></ApexChart>
    </div>
  </div>
</template>

<script>
import ReportService from '@/lib/report-service-v2';
import { downloadFormattedCSV } from '@/lib/download-file';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import { format } from 'date-fns';

export default {
  components: {
    LoadingSpinner
  },
  data() {
    return {
      options: {
        chart: {
          id: 'totalSalesLast30Days',
          redrawOnParentResize: true,
          toolbar: {
            tools: {
              download: true
            },
            export: {
              csv: {
                filename: 'Total Sales - Last 30 Days',
                headerCategory: 'Day',
                valueFormatter(y) {
                  return '$' + y;
                }
              }
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return '$' + value;
            }
          }
        },
        xaxis: {
          title: { text: undefined },
          type: 'category'
        },
        colors: ['#88BF4D'],
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top'
            }
          }
        },
        dataLabels: {
          formatter: function (value) {
            if (value >= 1000) {
              return `$${(value / 1000).toFixed(1)}K`;
            }
            return '$' + value;
          },
          style: {
            fontfamily: 'Futura PT',
            fontWeight: 'bold',
            fontSize: '14px',
            colors: ['#58627C']
          },
          offsetY: -30
        }
      },
      categories: [],
      organizationId: null,
      csvData: null,
      loading: true
    };
  },
  async mounted() {
    this.getSalesReport();
  },
  methods: {
    async getSalesReport() {
      try {
        this.loading = true;
        this.organizationId = (await getAuth().sessionUser()).organizationUuid;
        const result = await ReportService.retrieveTotalSalesReport({ orgId: this.organizationId });

        // Map report data keys to X and Y and format dates/currency as such
        const mappedReportData = result.data.map(({ date: x, dailySalesCents: y }) => {
          const formattedX = format(this.parseISO(x), 'PP');
          const formattedY = y / 100;
          return { y: formattedY, x: formattedX };
        });

        // Update chart data
        this.series = [{ name: 'Amount Sold', data: mappedReportData }];
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      } finally {
        this.loading = false;
      }
    },
    async downloadReport() {
      this.buttonLoading = true;
      try {
        this.csvData = await ReportService.retrieveTotalSalesReport({
          orgId: this.organizationId,
          contentType: 'text/csv'
        });

        downloadFormattedCSV('sales-report.csv', this.csvData);
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      } finally {
        this.buttonLoading = false;
      }
    }
  }
};
</script>
