<template>
  <div v-if="decommissionMetabase" class="grid grid-cols-1 xxl:grid-cols-3 gap-6">
    <div class="col-span-1">
      <div>
        <DashboardSalesReports />
      </div>
    </div>
    <div class="col-span-2 pt-8 xl:p-0">
      <!-- div for metabase replacement charts -->
      <div>
        <TotalSalesReport />
      </div>
    </div>
  </div>
  <div v-else>
    <MetabaseDashboard dashboardId="5210a84b-f102-4fb9-891a-cc41e48dec47" height="700" />
  </div>
</template>

<script>
import MetabaseDashboard from '@/components/reports/MetabaseDashboard';
import DashboardSalesReports from './reports/DashboardSalesReports.vue';
import TotalSalesReport from './reports/TotalSalesReport.vue';
import { unleashFeatureEnabled, UnleashKeys } from '../lib/unleash';

export default {
  components: {
    MetabaseDashboard,
    DashboardSalesReports,
    TotalSalesReport
  },
  data() {
    return {
      salesSummaryUrl: null,
      showDashboardComponents: false,
      decommissionMetabase: false
    };
  },
  async created() {
    this.showDashboardComponents = true;
  },
  async mounted() {
    this.decommissionMetabase = await unleashFeatureEnabled(UnleashKeys.DecommissionMetabase);
  }
};
</script>

<style></style>
